import { FC, useState } from 'react'
import { ApplicationFrame } from '../../components/ApplicationFrame'
import toastLogo from '../../assets/toast/logo.png'
import {
  Autocomplete,
  ButtonGroup,
  FormControl,
  FormHelperText,
  FormLabel,
  TextField,
  Typography,
} from '@mui/material'
import { useSearchParams } from 'react-router-dom'
import {
  useClientApiCall,
  useClientApiMutation,
} from '../../hooks/useClientApiCall'
import { UseQueryResult } from 'react-query/types'
import {
  Box,
  Divider,
  Grid,
  Paper,
  Tooltip,
  IconButton,
  Button,
  List,
  ListItem,
  ListItemText,
  Select,
  MenuItem,
} from '@mui/material'
import { CopyAll } from '@mui/icons-material'

export interface WithConnection {
  connection: UseQueryResult<any, unknown>
}

/**
 * Uber Eats Application
 */
export const ToastPOS: FC = () => {
  const [params] = useSearchParams()
  const session = params.get('session')!
  const connection = useClientApiCall<any>(session, 'connection')
  /**
   * Derived State
   */
  const isToastConnected =
    connection.data?.connection.externalLocationId !== undefined

  /**
   *
   */
  return (
    <ApplicationFrame heroImage={toastLogo} backgroundColor="#ff">
      {/* Centralised Title with padding */}
      <Typography variant="h4" align="center" sx={{ p: 2 }}>
        Toast POS
      </Typography>

      {/* Connected View */}
      {isToastConnected && <ToastPOSConnected connection={connection} />}

      {/* Disconnected View */}
      {!isToastConnected && <ToastPOSDisconnected connection={connection} />}
    </ApplicationFrame>
  )
}

export interface ToastPOSConnectedProps extends WithConnection {}

export const ToastPOSConnected: FC<ToastPOSConnectedProps> = ({
  connection,
}) => {
  const [params] = useSearchParams()
  const session = params.get('session')!
  const updateConnection = useClientApiMutation(session, 'connection')
  const [updates, setUpdates] = useState<any>({})

  /**
   * Helper function used to set a value on the updates object
   */
  const setUpdate = (key: string, value: any) =>
    setUpdates((prev: any) => ({ ...prev, [key]: value }))

  /**
   * Derive a flag to determine if there are updates in the updates object to save
   */
  const hasUpdates = Object.keys(updates).length > 0

  const onSave = () => {
    updateConnection.mutate(updates)
  }

  return (
    <Box sx={{ p: 2 }}>
      <Paper sx={{ p: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1">
              Please select the menu you would like to use for this location,
              only the products in the selected menu will be will be imported
            </Typography>
          </Grid>

          {/* Revenue Selector Selector */}
          <Grid item xs={12}>
            <FormControl fullWidth size="small">
              <FormLabel>Select Revenue Center</FormLabel>
              <Select
                label="Select Revenue Center"
                required
                defaultValue={
                  connection.data?.connection.toastRevenueCenterGuid
                }
                value={updates.toastRevenueCenterGuid}
                onChange={(e) =>
                  setUpdate('toastRevenueCenterGuid', e.target.value)
                }
                disabled={updateConnection.isLoading}
              >
                {connection.data?.revenueCenters.map((revenueCenter: any) => (
                  <MenuItem
                    key={revenueCenter.guid}
                    value={revenueCenter.guid}
                    selected={
                      revenueCenter.guid === updates.toastRevenueCenterGuid
                    }
                  >
                    {revenueCenter.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormHelperText>
              Select the revenue center that should be used for this location
            </FormHelperText>
          </Grid>

          {/* Menus Selector */}
          <Grid item xs={12}>
            <FormControl fullWidth size="small">
              <FormLabel>Select Menu</FormLabel>
              <Select
                label="Select Menu"
                required
                defaultValue={connection.data?.connection.toastSelectedMenuId}
                value={updates.toastSelectedMenuId}
                onChange={(e) =>
                  setUpdate('toastSelectedMenuId', e.target.value)
                }
                disabled={updateConnection.isLoading}
              >
                {connection.data?.menus.map((menu: any) => (
                  <MenuItem
                    key={menu.guid}
                    value={menu.guid}
                    selected={menu.guid === updates.toastSelectedMenuId}
                  >
                    {menu.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormHelperText>
              Only categories, products, and modifiers from the selected menu
              will be imported to the POS Hub catalog
            </FormHelperText>
          </Grid>

          {/* Payment for Payment Tyoes */}
          <Grid item xs={12}>
            <Typography variant="body1">
              Configure the payment types that should be used for each payment
              type.
            </Typography>
          </Grid>

          {/* Mapping for Payment Types */}
          <Grid item xs={12}>
            <Typography variant="body2">Online Payment Type</Typography>
            <Autocomplete
              size="small"
              disableClearable
              options={connection.data?.alternativePaymentTypes}
              defaultValue={
                connection.data?.connection.toastOnlinePaymentTypeId
                  ? connection.data?.alternativePaymentTypes?.find(
                      (option: any) =>
                        option.guid ===
                        connection.data?.connection.toastOnlinePaymentTypeId
                    )
                  : null
              }
              getOptionLabel={(option: any) => option.name}
              onChange={(_, value) =>
                setUpdate('toastOnlinePaymentTypeId', value.guid)
              }
              renderInput={(params) => <TextField {...params} />}
            />
          </Grid>

          {/* Mapping for Delivery, Collection */}
          <Grid item xs={12}>
            <Typography variant="body1">
              Configure what dining options should be used for each fulfillment
              type.
            </Typography>
          </Grid>

          {/* Delivery */}
          <Grid item xs={12}>
            <Typography variant="body2">Delivery</Typography>
            <Autocomplete
              size="small"
              disableClearable
              options={connection.data?.diningOptions}
              defaultValue={
                connection.data?.connection.toastDeliveryDiningOptionGuid
                  ? connection.data?.diningOptions?.find(
                      (option: any) =>
                        option.guid ===
                        connection.data?.connection
                          .toastDeliveryDiningOptionGuid
                    )
                  : null
              }
              getOptionLabel={(option: any) => option.name}
              onChange={(_, value) =>
                setUpdate('toastDeliveryDiningOptionGuid', value.guid)
              }
              filterOptions={(options: any) =>
                options.filter((option: any) => option.behavior === 'DELIVERY')
              }
              renderInput={(params) => <TextField {...params} />}
            />
          </Grid>

          {/* TAKE_OUT */}
          <Grid item xs={12}>
            <Typography variant="body2">Collection</Typography>
            <Autocomplete
              size="small"
              disableClearable
              options={connection.data?.diningOptions}
              defaultValue={
                connection.data?.connection.toastPickupDiningOptionGuid
                  ? connection.data?.diningOptions?.find(
                      (option: any) =>
                        option.guid ===
                        connection.data?.connection.toastPickupDiningOptionGuid
                    )
                  : null
              }
              isOptionEqualToValue={(option, value) =>
                option.guid === value.guid
              }
              getOptionLabel={(option: any) => option.name}
              onChange={(_, value) =>
                setUpdate('toastPickupDiningOptionGuid', value.guid)
              }
              filterOptions={(options: any) =>
                options.filter((option: any) => option.behavior === 'TAKE_OUT')
              }
              renderInput={(params) => <TextField {...params} />}
            />
          </Grid>

          {/* Dine In */}
          <Grid item xs={12}>
            <Typography variant="body2">Dine In</Typography>
            <Autocomplete
              size="small"
              disableClearable
              options={connection.data?.diningOptions}
              defaultValue={
                connection.data?.connection.toastDineInDiningOptionGuid
                  ? connection.data?.diningOptions?.find(
                      (option: any) =>
                        option.guid ===
                        connection.data?.connection.toastDineInDiningOptionGuid
                    )
                  : null
              }
              getOptionLabel={(option: any) => option.name}
              value={updates.toastDineInDiningOptionGuid}
              onChange={(_, value) =>
                setUpdate('toastDineInDiningOptionGuid', value.guid)
              }
              filterOptions={(options: any) =>
                options.filter((option: any) => option.behavior === 'DINE_IN')
              }
              renderInput={(params) => <TextField {...params} />}
            />
          </Grid>

          {/* float right */}
          <Grid item xs={12} sx={{ textAlign: 'right' }}>
            <ButtonGroup>
              <Button color="error">Disconnect</Button>
              <Button
                color="primary"
                onClick={onSave}
                disabled={updateConnection.isLoading || !hasUpdates}
              >
                Save
              </Button>
            </ButtonGroup>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  )
}

export interface ToastPOSDisconnectedProps extends WithConnection {}

export const ToastPOSDisconnected: FC<ToastPOSDisconnectedProps> = ({
  connection,
}) => {
  /**
   * Render a UI that presents the user with two values that they need to copy and apply to their toast POS
   * the first is group id which we will use the accountId and the location id will be the locationId,
   * for the UI and layout will we use the following copmponents from mui:
   *
   * - Typography
   * - Box
   * - Button
   * - Divider
   * - Grid
   * - Paper
   * - TextField
   * - Tooltip
   * - IconButton
   * - CopyToClipboard
   *
   */
  return (
    <Box sx={{ p: 2 }}>
      <Box sx={{ p: 2 }}>
        <Paper sx={{ p: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body2">
                Please copy the unique identifiers below and enter them into the
                Toast POS settings to complete the connection.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2">
                Group ID: {connection.data?.connection.accountId}
                <Tooltip title="Copy Group ID">
                  <IconButton>
                    <CopyAll />
                  </IconButton>
                </Tooltip>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2">
                Location ID: {connection.data?.connection.locationId}
                <Tooltip title="Copy Location ID">
                  <IconButton>
                    <CopyAll />
                  </IconButton>
                </Tooltip>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2">
                Once the configuration is completed within the Toast POS
                settings this page will automatically update, please allow up to
                5 minutes for the connection to be verified.
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Box>
  )
}
