import { FC } from 'react'
import { ApplicationFrame } from '../../components/ApplicationFrame'
import stuartHeroImage from '../../assets/relay-delivery/logo.png'
import { Typography } from '@mui/material'

/**
 * Uber Eats Application
 */
export const RelayDelivery: FC = () => {
  return (
    <ApplicationFrame heroImage={stuartHeroImage} backgroundColor="#eae8e8">
      {/* Centralised Title with padding */}
      <Typography variant="h4" align="center" sx={{ p: 2 }}>
        Relay Delivery
      </Typography>
    </ApplicationFrame>
  )
}
